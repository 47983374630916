import { AmberError } from "src/app/snatch/models/amber-response.model";
import { PaymentType } from 'src/app/common/common-checkout/models/generic-payment-output.model';
import { SalesOrder } from 'src/app/snatch/models/sales-order.model';
import { Address } from 'src/app/snatch/models/address.model';
import { Adyen3DSecureCCData } from "src/app/common/common-checkout/models/payment.model";
import { HttpErrorResponse } from "@angular/common/http";

export class GoToPlaceOrder {
  static readonly type = '[Checkout] Go To Place Order';
  constructor() {}
}
export class GoToOverview {
  static readonly type = '[Checkout] Go To Overview';
  constructor(public referenceId?: string) {}
}
export class GoToDetails {
  static readonly type = '[Checkout] Go To Details';
  constructor() {}
}
export class PrepareCheckout {
  static readonly type = '[Checkout] Prepare Checkout';
  constructor(public billingAddressId?: string, public shippingAddressId?: string) {}
}
export class PrepareCheckoutSuccess {
  static readonly type = '[Checkout] Prepare Checkout success';
  constructor(public jwt: string, public checkout: any) {}
}
export class PrepareCheckoutFailure {
  static readonly type = '[Checkout] Prepare Checkout failure';
  constructor(public payload: HttpErrorResponse) {}
}

export class ShippingAddressChange {
  static readonly type = '[Checkout] Shipping Address changed';
  constructor(public newShippingAddress: Address) {}
}

export class PaymentMethodChange {
  static readonly type = '[Checkout] Payment Method Change';
  constructor(public paymentMethod: PaymentType) {}
}

export class ApprovalPolicyCheck {
  static readonly type = '[Checkout] Check if any policy rules apply to the order value';
  constructor() {}
}

export class ApprovalPolicyCheckSuccess {
  static readonly type = '[Checkout] Approval policy check success';
  constructor(public policy: any) {}
}

export class ApprovalPolicyCheckFailure {
  static readonly type = '[Checkout] Approval policy check failure';
  constructor(public error: AmberError) {}
}

export class Pay {
  static readonly type = '[Checkout] Pay';
  constructor(public readonly payload: {adyen3DSecureCCData: Adyen3DSecureCCData, referenceId: string}) { }
}
export class PaySuccess {
  static readonly type = '[Checkout] Pay success';
  constructor(public readonly salesOrder: SalesOrder) {}
}
export class PayFailure {
  static readonly type = '[Checkout] Pay failure';
  constructor(public payload: AmberError) {}
}

export class ClearCheckoutState {
  static readonly type = '[Checkout] Clear Checkout State';
  constructor() {}
}

export class FetchAddresses {
  static readonly type = '[Checkout] Fetch all available addresses for user';
  constructor() {}
}

export class FetchAddressesSuccess {
  static readonly type = '[Checkout] Fetching all available addresses succeeded';
  constructor(public addresses: Array<Address>) {}
}

export class FetchAddressesFailure {
  static readonly type = '[Checkout] Fetching all available addresses failed';
  constructor(public payload: AmberError) {}
}

export class SaveAddress {
  static readonly type = '[Checkout] Save address for user';
  constructor(public address: Address) {}
}

export class DeleteAddress {
  static readonly type = '[Checkout] Delete address for user';
  constructor(public address: Address) {}
}

export class SaveAddressSuccess {
  static readonly type = '[Checkout] Save address success';
  constructor(public address: Address) {}
}

export class SaveAddressFailure {
  static readonly type = '[Checkout] Save address failure';
  constructor(public payload: AmberError) {}
}

export class DeleteAddressSuccess {
  static readonly type = '[Checkout] Delete address success';
  constructor(public address: Address) {}
}

export class DeleteAddressFailure {
  static readonly type = '[Checkout] Save address failure';
  constructor(public payload: AmberError) {}
}

export class ClearAddressState {
  static readonly type = '[Checkout] Clear out states for address saving or deleting';
  constructor() {}
}

export class FetchCountryZipCodePatterns {
  static readonly type = '[Checkout] Fetch zip patterns for all addresses';
  constructor() {}
}

export class GetAccountCreditLimit {
  static readonly type = '[Checkout] Get credit limit per account';
  constructor() {}
}

export class GetAccountCreditLimitSuccess {
  static readonly type = '[Checkout] Get credit limit per account success';
  constructor(public payload: any) {}
}

export class GetAccountCreditLimitError {
  static readonly type = '[Checkout] Get credit limit per account failure';
  constructor(public payload: AmberError) {}
}

export class ShowCheckoutOverviewAfterAdyenCallback {
  static readonly type = '[Checkout] Show Checkout Overview after Adyen Callback';
  constructor(public readonly orderNumber: string, public readonly payload: string) { }
}

export class ClearErrorMessageState {
  static readonly type = '[Checkout] Clear Error Message State';
  constructor() {}
}
