<nz-breadcrumb nzSeparator=">">
  <nz-breadcrumb-item *ngFor="let item of breadcrumbs">
    <a *ngIf="item.path" [routerLink]="item.path" [queryParams]="item.queryParams">
      {{ item.queryParams ? 'Return to search results' : item.label }}
    </a>
    <span *ngIf="!item.path">{{ item.label }}</span>
  </nz-breadcrumb-item>
</nz-breadcrumb>

<div nz-row style="margin-top: 5px; margin-left: 5px; margin-right: 5px;">
  <div class="left-section-container" nz-col nzMd="15" style="padding: 0 5px;">
    <ng-container *ngIf="!(shippingBatches$ | async).length; else leftSection">
      <nz-card style="margin-top: 6px;">
        <div class="empty-cart-container">
          <h2>Your shopping cart is empty!</h2>
          <p class="empty-cart-description">It appears that you have not added any items to your shopping cart yet. <a (click)="goBackToProducts()">Continue Shopping</a></p>
        </div>
      </nz-card>
    </ng-container>
  </div>
  <div class="right-section-container" nz-col nzMd="9" style="padding: 0 5px;">
      <ng-container *ngTemplateOutlet="rightSection"></ng-container>
      <nz-card>
        <button i18n="@@shopping-cart-drawer.buttons.proceed-to-checkout"
          [disabled]="!(shippingBatches$ | async)?.length"
          nz-button nzBlock nzType="primary" (click)="proceedToCheckout()">
          Proceed to Checkout
        </button>
      </nz-card>
  </div>
</div>
<div class="layout-margin-bottom"></div>

<ng-template #leftSection>
  <leap-shopping-cart-review-section
    [countriesMap]="countriesMap$ | async"
    [defaultShippingAddress]="defaultShippingAddress$ | async"
    [shippingBatches]="shippingBatches$ | async"
    [currency]="currency$ | async"
    [productTypesMap]="productTypesMap$ | async"
    [languagesMap]="languagesMap$ | async"
    [deliveryFormatsMap]="deliveryFormatsMap$ | async"
    [certificateTypesMap]="certificateTypesMap$ | async"
    [currencySignsMap]="currencySignsMap$ | async"
    
    [calculating$]="calculating$"
    (remove)="removeLineItem($event)"
    (quantityChange)="quantityChangeLineItem($event)"
    (changeMethod)="changeMethod($event)"></leap-shopping-cart-review-section>
</ng-template>

<ng-template #rightSection>
  <leap-order-summary-form
    [calculating$]="calculating$"
    [currency]="currency$ | async"
    [currencySignsMap]="currencySignsMap$ | async"
    [subtotalAmount]="netTotal$ | async"
    [surchargeAmount]="surchargeTotal$ | async"
    [shippingAmount]="shippingTotal$ | async"
    [totalAmount]="grossTotal$ | async"
    [surchargeAmount]="surchargeTotal$ | async"
    [feeAmount]="taxTotal$ | async"
    [appliedPromotionCodes]="appliedPromotionCodes$ | async"
    [invalidPromotionCodes]="invalidPromotionCodes$ | async"
    [promotionCodes]="promotionCodes$ | async"

    [addPromotionCodeError]="addPromotionCodeError$ | async"
    [removePromotionCodeError]="removePromotionCodeError$ | async"

    (addPromotionCode)="addPromotionCode($event)"
    (removePromotionCode)="removePromotionCode($event)"></leap-order-summary-form>
</ng-template>
