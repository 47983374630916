import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class EnvironmentService {
    constructor() {
    }

    public get getHost() {
        return environment.host;
    }

    public get getWelcomePageCampaignKey() {
        return environment.welcomePageCampaignKey;
    }

    public get getWelcomePagePromotionKey() {
        return environment.welcomePagePromotionKey;
    }

    public getBrandedPortalName(domain) {
        const location = document.location;
        const address = location.protocol + '//' + domain + '.' + environment.host;
        return location.port ? address + ':' + location.port : address;
    }

    public get envName() {
        return environment.env;
    }

    public get baseUrl() {
        return document.location.origin;
    }

    public get amberUrl() {
        return environment.amberUrl;
    }

    public get amberBaseUrl() {
        return environment.amberBaseUrl;
    }

    public get saharaUrl(): string {
        return environment.saharaUrl;
    }

    public get instructorMarketUrl(): string {
        return environment.instructorMarketUrl;
    }

    public get serviceMarketUrl(): string {
        return environment.serviceMarketUrl;
    }

    public get bouncerUrl() {
        return environment.bouncerUrl;
    }

    public get sphinxUrl() {
        return environment.sphinxUrl;
    }

    public get uploadsBucket() {
        return environment.aws.uploadsBucket;
    }

    public get s3Url() {
        return environment.aws.s3Url;
    }

    public get cdnBucket() {
        return environment.aws.cdnBucket;
    }

    public get awsKey() {
        return environment.aws.key;
    }

    get adyenClientKey(): string {
        return environment.adyen.clientKey;
    }

    public get defaultProfilePhotoUrl() {
        return `${this.s3Url}/${this.cdnBucket}/images/instructors/default-profile-photo.svg`;
    }

    public get hedwigUrl() {
        return environment.hedwigUrl;
    }

    public get integrationFramework() {
        return environment.integrationFramework;
    }

    public get isProdin(): boolean {
        return this.envName.toLowerCase() === 'prodin';
    }
    public get isQA(): boolean {
        return this.envName.toLowerCase() === "test";
    }

    public get isPreview(): boolean {
        return this.envName.toLowerCase() === "preview";
    }
    public get isEtisalat(): boolean {
        return this.envName.toLowerCase() === "qa" || this.envName.toLowerCase() === "preview-etisalat" || this.envName.toLowerCase() === "prod-uswest2";
    }
    public get oktaPrefix(): string {
        return environment.okta.prefix
    }
}
