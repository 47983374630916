import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Configuration } from 'src/app/snatch/models/configuration.model';
import { PromotionCode } from 'src/app/common/common-shopping-cart/models/promotion-code.model';
import { Observable } from 'rxjs';
import { GraphQLError } from "../../../../snatch/models/graphql-error.model";
import { AmberError } from 'src/app/snatch/models/amber-response.model';

@Component({
  selector: 'leap-order-summary-form',
  templateUrl: './order-summary-form.component.html',
  styleUrls: ['./order-summary-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSummaryFormComponent implements OnInit {

  @Input() recordedTransaction: true;
  @Input() calculating$: Observable<boolean>;
  @Input() freeze: boolean; //  If true, we don't allow modifications on the order such as promotion codes add/remove
  @Input() paymentStatus: Configuration;
  @Input() currency: string;
  @Input() subtotalAmount: string;
  @Input() surchargeAmount: string;
  @Input() totalAmount: string;
  @Input() shippingAmount: string;
  @Input() feeAmount: string;
  @Input() appliedPromotionCodes: PromotionCode[];
  @Input() invalidPromotionCodes: PromotionCode[];
  @Input() promotionCodes: PromotionCode[];
  @Input() currencySignsMap: Map<string, string>;
  @Output() removePromotionCode: EventEmitter<string> = new EventEmitter();
  @Output() addPromotionCode: EventEmitter<string> = new EventEmitter();

  @Input() addPromotionCodeError: AmberError;
  @Input() removePromotionCodeError: AmberError;


  promotionCode: string;

  constructor() { }

  ngOnInit() {

  }

  removePromotionCodeFn(promotionCode: string) {
    this.removePromotionCode.emit(promotionCode);
  }

  addPromotionCodeFn() {
    if (!this.promotionCode) return;
    this.addPromotionCode.emit(this.promotionCode);
    this.promotionCode = undefined;
  }

}
