<span>{{ currencySignsMap?.get(currency) }} {{ netAmount | number : '1.2-2' }}</span>
<ng-container *ngIf="surchargeTotal">
    <small>SURCHARGES</small>
    <span class="font-small surcharge">{{ currencySignsMap?.get(currency) }} {{ surchargeTotal | number : '1.2-2' }}
    <i class="info-circle"
       nz-popover [nzContent]="surchargesInfoTemplate" nzTrigger="hover"
       nz-icon type="info-circle" theme="fill"></i></span>
</ng-container>
<ng-template #surchargesInfoTemplate>
    <strong class="surcharge-main">The following additional costs are applied:</strong>
    <div nz-row class="font-small">
        <div nz-col nzMd="16">Type</div>
        <div nz-col nzMd="8" class="right">Cost</div>
    </div>
    <hr>
    <div nz-row class="font-small vertical-margin-10" *ngFor="let surcharge of surcharges">
        <div nz-col nzMd="16">{{ surcharge.name }}</div>
        <div nz-col nzMd="8" class="right font-big">{{ currencySignsMap?.get(currency) }} {{ surcharge.amount | number : '1.2-2' }}</div>
    </div>
</ng-template>
