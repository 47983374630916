import gql from 'graphql-tag';
import { ADDRESS_FRAGMENT } from './address.fragment';
export const SHOPPING_CART = gql`
  fragment basketViewFragment on BasketView {
    id,
    netTotal,
    grossTotal,
    taxTotal,
    discountTotal,
    surchargeTotal,
    shippingTotal,
    currency,
    defaultShippingAddress {
      ...addressFragment
    }
    items {
      id,
      quantity,
      netAmount,
      netAmountWithSurcharges,
      discountTotal,
      surchargesAmount,
      surchargeTotal,
      netTotalWithSurcharges,
      purchaseOrderIds
      
      surcharges {
        key,
        name,
        amount
      },
      listItems: lineItems {
        quantity,
        
        netTotal,
        netTotalWithDiscount
        
        netAmount,
        netAmountWithDiscount
        
        discountAmount,
        discountPercentage,
        
        promoCodes
      },
      bundleSnapshot {
         sku
         name
         licenseSku
         language
         sellerName
         currency
         price
         licenseType
         licenseValue
         duration
         durationType
         durationTypeValue
         bundleThumbnailUrl
         expiryDate
         productsCount
        },
      productVariantSnapshot {
          sku,
          name,
        	logoUrl,
          productType,
          language,
          deliveryFormat,
          certificateType,
          productTypeConfigValue
          languageConfigValue
          certificateTypeConfigValue
          deliveryFormatConfigValue
      },
      productVariant {
        product {
          sku,
          seller {
            name,
            logo
          }
        }
      }
    },
    shippingGroups {
      id,
      basketItemIds,
      shippingMethods {
        id,
        key,
        name,
        shipsWithin,
        arrivesWithin,
        totalEstimatedArrival,
        totalAmount,
        shipsToSelectedAddress
      }
      selectedMethod {
        id,
        key,
        name,
        shipsWithin,
        arrivesWithin,
        totalEstimatedArrival,
        totalAmount,
        shipsToSelectedAddress
      }
    }
    appliedPromoCodes {
      code,
      totalDiscount
    },
    invalidPromoCodes {
      code,
     	totalDiscount
    }
  }
  ${ADDRESS_FRAGMENT}
`;
