import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { RemoveLineItemFromShoppingCart, QuantityChangeLineItemInShoppingCart, AddPromotionCode, RemovePromotionCode, FetchPromotionCodes, EditShippingMethod, } from '../../actions/shopping-cart.actions';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { BREADCRUMBS_CONFIGURATION } from 'src/app/core/configurations/breadcrumbs.config';
import { GoToDetails } from "../../../checkout/actions/checkout.actions";
import { BreadcrumbsBuildingService } from 'src/app/core/services/breadcrumbs-building.service';
const ɵ0 = state => state.shoppingCart.shippingBatches, ɵ1 = state => state.shoppingCart.defaultShippingAddress, ɵ2 = (state) => state.shoppingCart.appliedPromotionCodes, ɵ3 = (state) => state.shoppingCart.invalidPromotionCodes, ɵ4 = (state) => state.shoppingCart.promotionCodes, ɵ5 = state => state.shoppingCart.editShippingMethodPending, ɵ6 = state => state.shoppingCart.addPromotionCodePending, ɵ7 = state => state.shoppingCart.addPromotionCodeError, ɵ8 = state => state.shoppingCart.removePromotionCodePending, ɵ9 = state => state.shoppingCart.removePromotionCodeError, ɵ10 = state => state.shoppingCart.quantityChangeLineItemPending, ɵ11 = state => state.shoppingCart.netTotal, ɵ12 = state => state.shoppingCart.grossTotal, ɵ13 = state => state.shoppingCart.taxTotal, ɵ14 = state => state.shoppingCart.surchargeTotal, ɵ15 = state => state.shoppingCart.shippingTotal, ɵ16 = state => state.shoppingCart.currency, ɵ17 = (state) => state.core.dictionaries.languagesMap, ɵ18 = (state) => state.core.dictionaries.deliveryFormatsMap, ɵ19 = (state) => state.core.dictionaries.certificateTypesMap, ɵ20 = (state) => state.core.dictionaries.productTypesMap, ɵ21 = (state) => state.core.dictionaries.currencySignsMap, ɵ22 = (state) => state.core.dictionaries.countriesMap;
export class ShoppingCartPageComponent {
    constructor(store, router, breadcrumbsBuildingService) {
        this.store = store;
        this.router = router;
        this.breadcrumbsBuildingService = breadcrumbsBuildingService;
        this.breadcrumbs = this.breadcrumbsBuildingService.buildBreadcrumbsWithQueryParams([BREADCRUMBS_CONFIGURATION.Products, BREADCRUMBS_CONFIGURATION.ShoppingCart]);
    }
    ngOnInit() {
        this.store.dispatch(new FetchPromotionCodes());
        this.calculating$ = combineLatest(this.quantityChangeLineItemPending$, this.editShippingMethodPending$, this.addPromotionCodePending$, this.removePromotionCodePending$)
            .pipe(map(([a, b, c]) => (a || b || c)));
    }
    removeLineItem(lineItem) {
        this.store.dispatch(new RemoveLineItemFromShoppingCart(lineItem));
    }
    quantityChangeLineItem(lineItem) {
        this.store.dispatch(new QuantityChangeLineItemInShoppingCart(lineItem));
    }
    changeMethod(payload) {
        this.store.dispatch(new EditShippingMethod(payload.shippingGroupId, payload.shippingMethodId));
    }
    addPromotionCode(promotionCode) {
        this.store.dispatch(new AddPromotionCode(promotionCode));
    }
    removePromotionCode(promotionCode) {
        this.store.dispatch(new RemovePromotionCode(promotionCode));
    }
    proceedToCheckout() {
        // this.router.navigate(['checkout']);
        this.router.navigate(['checkout']).then(_ => {
            this.store.dispatch(new GoToDetails());
        });
    }
    goBackToProducts() {
        this.router.navigate(['products']);
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "shippingBatches$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "defaultShippingAddress$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "appliedPromotionCodes$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "invalidPromotionCodes$", void 0);
tslib_1.__decorate([
    Select(ɵ4),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "promotionCodes$", void 0);
tslib_1.__decorate([
    Select(ɵ5),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "editShippingMethodPending$", void 0);
tslib_1.__decorate([
    Select(ɵ6),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "addPromotionCodePending$", void 0);
tslib_1.__decorate([
    Select(ɵ7),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "addPromotionCodeError$", void 0);
tslib_1.__decorate([
    Select(ɵ8),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "removePromotionCodePending$", void 0);
tslib_1.__decorate([
    Select(ɵ9),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "removePromotionCodeError$", void 0);
tslib_1.__decorate([
    Select(ɵ10),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "quantityChangeLineItemPending$", void 0);
tslib_1.__decorate([
    Select(ɵ11),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "netTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ12),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "grossTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ13),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "taxTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ14),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "surchargeTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ15),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "shippingTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ16),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "currency$", void 0);
tslib_1.__decorate([
    Select(ɵ17),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "languagesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ18),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "deliveryFormatsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ19),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "certificateTypesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ20),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "productTypesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ21),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "currencySignsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ22),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartPageComponent.prototype, "countriesMap$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22 };
