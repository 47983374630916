<nz-table style="margin-top: -3px;" class="main-table" #table [nzData]="[1]" [nzShowPagination]="false">
  <thead class="white">
    <tr><td style="padding: 0px 20px;" i18n="shopping-cart.review-section.product-items">Product Items</td></tr>
  </thead>
</nz-table>

<div *ngFor="let batch of shippingBatches">
  <nz-spin [nzSpinning]="calculating$ | async" [nzIndicator]="loadingIndicatorTemplate">
    <nz-card>
      <div style="margin-bottom: 10px;" *ngIf="batch.shippingMethods">
        <p class="shipping-methods-title" i18n="shopping-cart.review-section.shipping-methods-title">Select Delivery Shipping Method to apply for the items listed below:</p>
        <p *ngIf="defaultShippingAddress" class="shipping-methods-subtitle" i18n="shopping-cart.review-section.shipping-methods-subtitle">Item shipping to {{ defaultShippingAddress.firstName}} {{ defaultShippingAddress.lastName }} ({{ defaultShippingAddress.email }}),  {{ defaultShippingAddress.streetName }} {{ defaultShippingAddress.houseNumber }} {{ defaultShippingAddress.city}}, {{ countriesMap?.get(defaultShippingAddress.country) }}</p>

        <leap-shipping-method-form *ngIf="batch.selectedMethod.shipsToSelectedAddress; else noShipToSelectedAddressTemplate"
          [currencySignsMap]="currencySignsMap"
          [currency]="currency"
          [shippingMethods]="batch.shippingMethods"
          [selectedMethod]="batch.selectedMethod?.id"
          (changeMethod)="onMethodChange($event, batch.id)"></leap-shipping-method-form>

        <ng-template #noShipToSelectedAddressTemplate>
          <p class="shipping-info margin-vertical-8">The items listed below do not ship to your country</p>
        </ng-template>
      </div>
      <nz-table class="main-table" #table [nzData]="batch.lineItems" [nzShowPagination]="false">
        <thead>
          <tr leap-shopping-cart-thead-bar></tr>
        </thead>
        <tbody>
          <tr leap-line-item-verbose *ngFor="let item of batch.lineItems; let i = index"
            [id]="item.id"
            [sku]="item.productVariantSnapshot.sku"
            [masterProductSku]="item.productVariant.product.sku"
            [name]="item.productVariantSnapshot.name"
            [language]="item.productVariantSnapshot.language"
            [deliveryFormat]="item.productVariantSnapshot.deliveryFormat"
            [certificateType]="item.productVariantSnapshot.certificateType"
            [netAmountWithSurcharges]="item.netAmountWithSurcharges"
            [netTotalWithSurcharges]="item.netTotalWithSurcharges"
            [netAmount]="item.netAmount"
            [type]="item.productVariantSnapshot.productType"
            [currency]="currency"
            [quantity]="item.quantity"
            [imageUrl]="item.productVariantSnapshot.logoUrl"

            [sellerImageUrl]="item.productVariant?.product.seller.logo"
            [sellerName]="item.productVariant?.product.seller.name"

            [productTypesMap]="productTypesMap"
            [languagesMap]="languagesMap"
            [deliveryFormatsMap]="deliveryFormatsMap"
            [certificateTypesMap]="certificateTypesMap"
            [currencySignsMap]="currencySignsMap"

            [surcharges]="item.surcharges"
            [surchargeTotal]="item.surchargesAmount"

            (remove)="removeLineItem($event)"
            (quantityChange)="quantityChangeLineItem($event)"></tr>
        </tbody>
      </nz-table>
    </nz-card>
  </nz-spin>
</div>

<ng-template #loadingIndicatorTemplate>
    <i nz-icon nzType="loading" style="font-size: 24px;"></i>
</ng-template>
