import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LineItem } from '../../models/line-item.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Surcharge } from 'src/app/common/common-product/models/product-variant-pricing.model';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnvironmentService } from 'src/app/snatch/services';

@Component({
  selector: '[leap-line-item-verbose]',
  templateUrl: './line-item-verbose.component.html',
  styleUrls: ['./line-item-verbose.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemVerboseComponent implements OnInit {

  @Input() imageUrl: string;
  @Input() id: number;
  @Input() sku: string;
  @Input() name: string;
  @Input() type: string;
  @Input() language: string;
  @Input() deliveryFormat: string;
  @Input() certificateType: string;
  @Input() certificateTemplateName: string;
  @Input() masterProductSku: String;
  @Input() hasRestricting: boolean;

  @Input() netAmountWithSurcharges: number;
  @Input() netAmount: number;
  @Input() netTotalWithSurcharges: number;
  @Input() currency: string;
  @Input() quantity: number;
  @Input() surcharges: Surcharge[];
  @Input() surchargeTotal: number;

  @Input() sellerImageUrl: string;
  @Input() sellerName: string;
  @Input() sellerDomain: string;

  @Input() productTypesMap: Map<string, string>;
  @Input() languagesMap: Map<string, string>;
  @Input() deliveryFormatsMap: Map<string, string>;
  @Input() certificateTypesMap: Map<string, string>;
  @Input() currencySignsMap: Map<string, string>;

  @Output() quantityChange: EventEmitter<LineItem> = new EventEmitter();
  @Output() remove: EventEmitter<LineItem> = new EventEmitter();

  form: FormGroup;

  // Use for takeUntil to unsubscribe onDestroy
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private fb: FormBuilder, private envService: EnvironmentService) { }

  ngOnInit() {
    this.form = this.fb.group({
      id: [this.id, Validators.required],
      sku: [this.sku, Validators.required],
      masterProductSku: [this.masterProductSku, Validators.required],
      quantity: [this.quantity, Validators.required],
      language: [this.language, Validators.required],
      deliveryFormat: [this.deliveryFormat, Validators.required],
      certificateType: [this.certificateType]
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(800)
      )
      .subscribe(_ => {
        this.onQuantityChange();
      })

    if (this.hasRestricting) {
      this.form.controls['quantity'].setValue(0);
      this.form.controls['quantity'].disable();
    }
    if(this.envService.isQA || this.envService.isPreview || this.envService.isProdin){
        this.setProductType(this.deliveryFormat)
      }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToDetails(): void {
    if (!this.hasRestricting) {
      const queryParams = {
        language: this.form.value.language,
        deliveryFormat: this.form.value.deliveryFormat
      };

      if(this.form.value.certificateType)
        queryParams['certificateType'] = this.form.value.certificateType;

      this.router.navigate(['products', 'details', this.form.value.masterProductSku], {
        queryParams: queryParams
      });
    }
  }

  onRemove() {
    this.remove.emit(this.form.value)
  }

  onQuantityChange() {
    this.quantityChange.emit(this.form.value)
  }
  setProductType(deliveryFormat) {
    if(deliveryFormat === 'diagnostics') {
      this.type = 'Diagnostic';
    }else if (deliveryFormat === 'product-assessment') {
      this.type = 'Assessment';
    }else if (deliveryFormat === 'lab') {
      this.type = 'vLab';
    }
  }
}
